import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { ICreateEmbargoForm } from "containers/embargoes/views/NewEmbargo/components/EmbargoDetails/types"
import { TQuoteRenewal } from "models/quotes/quoteRenewal"
import { IDocument } from "models/documents/document"
import { IEmbargoesListItem } from "models/embargoes/embargoesList"
import { IPolicyEvent, IQuoteEvent } from "models/events/IEvent"
import { IPolicyBundle } from "models/policies/policy"
import { IPolicyActionWrapper } from "models/policies/policyAction"
import { IPolicyTable } from "models/policies/policyTable"
import {
  TPolicyRenewals,
  TPolicyRenewalsBundle,
  TPolicyRenewalsSettings
} from "models/policies/policyRenewals"
import { IPolicyHistoryData } from "models/policies/policyHistory"
import { IEndorsementOpus } from "models/quotes/endorsementOpus"
import { IMoney } from "models/quotes/money"
import { IPortalQuoteTable } from "models/quotes/portalQuoteTable"
import { IProductOpus } from "models/quotes/productOpus"
import {
  IQuoteReviewResponse,
  IQuoteReviewResult
} from "models/quotes/quoteReview"
import { IScheme } from "models/quotes/scheme"
import { createContext } from "react"
import { IQuoteState } from "models/quotes/quoteState"
import { Nullable } from "models/underwriting"
import {
  EUserGroups,
  IEndorsementV6,
  RefundMethod
} from "platform-client/types"
import { IQuoteChange } from "platform-client/types/quote"
import { TPayment, TPolicyPaymentPlan } from "models/policies/policyPaymentPlan"
import { IPolicyPaymentMethod } from "models/policies/policyPaymentMethod"
import { TPolicyPayment } from "models/policies/policyPayment"
import { ITransactionalMessage } from "models/documents/message"
import { IBlacklistedPhoneNumber } from "models/blocklist/blacklistedphonenumber"
import { IRateFile } from "models/ratings/IRateFile"
import { IProductRateVersion } from "models/ratings/IProductRateVersion"

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <OpusContext.Provider>")
}

export type CreateOpusClient = (
  token: string,
  options?: AxiosRequestConfig
) => AxiosInstance

export interface IEmbargoesClient {
  getEmbargo(id: string): Promise<IEmbargoesListItem | undefined>
  addEmbargo(data: ICreateEmbargoForm): Promise<IEmbargoesListItem | undefined>
  getEmbargoes(): Promise<IEmbargoesListItem[] | undefined>
  setEmbargoCancel(id: string): Promise<IEmbargoesListItem | undefined>
}

export interface IRenewalClient {
  setActiveRenewalQuote: (
    policyID: string,
    quoteID: string
  ) => Promise<IRenewalQuoteState | undefined>
  duplicateRenewalQuote: (
    policyID: string,
    quoteID: string
  ) => Promise<IRenewalQuoteState | undefined>
}

export interface ICommandClient {
  regenerateDocument: (
    policyId: Nullable<string>,
    quoteId: Nullable<string>,
    isPolicyCancellation: boolean
  ) => Promise<ICommandResponseViewModel | undefined>
}

export interface ICommandResponseViewModel {
  results: ICommandResultViewModel[]
}

export interface ICommandResultViewModel {
  commandText: string
  log: string[]
  successful: boolean
}

export interface IRenewalQuoteState {
  activeRenewalQuoteID: string
  allowAutoBind: boolean
  autoBindError: string
  autoBindSuccess: boolean
  automatedRenewalQuoteID: string
  automatedRenewalTimestamp: string
  doNotRenew: boolean
  pendingReview: boolean
  policyID: string
  renewalWithdrawnByID: string
  renewalWithdrawnTimestamp: string
  renewedPolicyID: string
  requiresReview: boolean
  status: string
}

export interface IPolicySearchResponse {
  results: IPolicyTable[]
}

export interface IPolicyCancellationReason {
  id: number
  referenceID: string
  text: string
}

export interface IPolicyCancellationInfo {
  cancellationMethod: CancellationMethodType
  cancellationReasons: IPolicyCancellationReason[]
  effectiveCancellationDate: string
  policyID: string
  policyRef: string
  previousRefundAmount: IMoney
  refundAmount: IMoney
  refundReason: RefundReasonType
}

export type CancellationMethodType = "NotSet" | "SelfService" | "AgentRequired"

export type RefundReasonType =
  | "WithinStatutoryPeriod"
  | "OutsideStatutoryPeriod"
  | "ClaimsRaised"
  | "AdminOverride"
  | "OutsideRealexRefundWindow"
  | "ClaimPayout"
  | "NotTakenUp"

export interface IPolicyCancelRequestBody {
  policyID: string
  selectedCancellationReason: IPolicyCancellationReason
  effectiveCancellationDate?: Nullable<string>
  requestedCancellationDate?: Nullable<string>
  allowRefund?: boolean
  suppressCancellationEmail?: boolean
  refundMethodOverride?: Nullable<string>
  additionalProductsToCancel?: string[]
}

export interface IPolicyCancelInfoRequestBody {
  policyID: string
  selectedCancellationReason?: Nullable<IPolicyCancellationReason>
  selectedCancellationReasonID?: Nullable<number>
  effectiveCancellationDate?: Nullable<string>
  requestedCancellationDate?: Nullable<string>
  allowRefund?: boolean
  suppressCancellationEmail?: boolean
  refundMethodOverride?: Nullable<RefundMethod>
  regenerateDocuments?: boolean
  additionalProductsToCancel?: string[]
}

export interface IShiftCancellationRequest {
  policyID: string
  effectiveCancellationDate?: Nullable<string>
  cancellationReasonReferenceID?: Nullable<string>
  issueRefund?: boolean
  regenerateDocuments?: boolean
  emailDocuments?: boolean
  refundMethodOverride?: Nullable<RefundMethod>
  additionalProductsToCancel?: string[]
}

export interface IPolicyReinstatementRequest {
  instalmentsTakenManually: Nullable<number>
  newPaymentDate: Nullable<string>
  suppressEmails: boolean
}

export interface IPolicyCancellationAmendmentRequest {
  policyID: string
  effectiveCancellationDate: string
  cancellationReason?: IPolicyCancellationReason
}

export interface IRenewalQuote {
  createdBy: string
  dateGenerated: string
  id: string
  policyHistoryID: string
  policyID: string
  quoteID: string
  requiresReview: boolean
  reviewActionedByID: string
  reviewActionTimestamp: string
  reviewApproved: boolean
  status: string
  statusReason: string
  userID: string
}

export interface IPolicyRenewalQuote {
  assignedToPolicyAsActive: boolean
  quote: Nullable<IQuote>
  renewalQuote: IRenewalQuote
}

export interface IPolicyManualRefundRequest {
  policyID: string
  amount: IMoney
  reference: Nullable<string>
  reason: Nullable<string>
  action: Nullable<string>
}

export interface IPolicyManualPaymentRequest {
  policyID: string
  amount: IMoney
  reference: Nullable<string>
  reason: Nullable<string>
}

export interface IPolicyClient {
  getPolicyHistory: (
    policyID: string
  ) => Promise<IPolicyHistoryData | undefined>
  getPolicies: () => Promise<IPolicyTable[] | undefined>
  getPolicyEvents: (policyID: string) => Promise<IPolicyEvent[] | undefined>
  getPolicyBundle: (policyID: string) => Promise<IPolicyBundle | undefined>
  getPolicyActions: (
    policyID: string
  ) => Promise<IPolicyActionWrapper | undefined>
  getPolicySearch: (query: string) => Promise<IPolicySearchResponse | undefined>
  getPolicyRenewals: (
    policyID: string
  ) => Promise<TPolicyRenewalsBundle | undefined>
  getPolicyRenewalsSettings: (
    policyProductID: string
  ) => Promise<TPolicyRenewalsSettings | undefined>
  setPolicyRenewals: (
    values: TPolicyRenewals,
    policyID: string
  ) => Promise<TPolicyRenewalsBundle | undefined>
  setPolicyRenewalQuote: (
    policyID: string
  ) => Promise<TQuoteRenewal | undefined>
  setPolicyRenewalWithdraw: (
    policyID: string
  ) => Promise<TPolicyRenewalsBundle | undefined>
  getPolicyRenewalQuotes: (
    policyID: string
  ) => Promise<IPolicyRenewalQuote[] | [] | undefined>
  getPolicyCancelInfo: (
    policyID: string
  ) => Promise<IPolicyCancellationInfo | undefined>
  getPolicyCancellationInformation: (
    policyID: string,
    request: IPolicyCancelInfoRequestBody
  ) => Promise<IPolicyCancellationInfo | undefined>
  setPolicyCancellation: (
    subjectUserID: string,
    requestBody: IPolicyCancelRequestBody
  ) => Promise<void>
  reinstatePolicy: (
    policyID: string,
    reinstatement: IPolicyReinstatementRequest
  ) => Promise<IPolicyBundle>
  updateCoverStartDate: (
    policyID: string,
    newCoverStartDate: string
  ) => Promise<IPolicyBundle>
  getPolicyPaymentPlan: (
    policyID: string
  ) => Promise<TPolicyPaymentPlan | undefined>
  manualRefund: (
    subjectUserID: string,
    requestBody: IPolicyManualRefundRequest
  ) => Promise<TPayment>
  manualPayment: (
    subjectUserID: string,
    requestBody: IPolicyManualPaymentRequest
  ) => Promise<TPayment>
  getPolicyPaymentMethod: (policyID: string) => Promise<IPolicyPaymentMethod>
  payScheduledPayment: (
    scheduledPaymentID: string
  ) => Promise<TPolicyPaymentPlan | undefined>
  getPolicyPayments: (policyID: string) => Promise<TPolicyPayment[]>
  downloadTransactionReceipt: (
    policyID: string,
    paymentID: string
  ) => Promise<{ data: ArrayBuffer; headers: { "x-filename": string } }>
  emailTransactionReceipt: (
    policyID: string,
    paymentID: string,
    emailAddress: string
  ) => Promise<boolean>
  getTransactionalMessages: (
    entityID: string
  ) => Promise<ITransactionalMessage[]>
  resendTransactionalEmail: (
    id: string,
    emailAddress: string
  ) => Promise<boolean>
  resendDocument: (
    documentId: string,
    quoteId: Nullable<string>,
    policyId: Nullable<string>,
    emailAddress: string,
    isCancellation: boolean
  ) => Promise<boolean>
  shiftCancellation: (
    request: IShiftCancellationRequest
  ) => Promise<IPolicyBundle>
}

export type UserGroup = {
  id: string
  type: string
  name: string
}

export type UserRole = {
  id: string
  name: string
}

export interface IUser {
  fullName: string
  id: string
  organizationID: number
  lastLogin: string
  groups: UserGroup[]
  roles: UserRole[]
  adminFlags: number
  authID: string
  status: string
  emailVerified: true
  firstName: string
  lastName: string
  username: string
  externalID: string
  companyName: string
}

export interface IUserClaim {
  claimType: string
  id: number
  name: string
  value: string
}

export interface IUserInsurer {
  user: IUser
  userClaims: IUserClaim[]
}

export interface IUserUpdateRequest {
  adminFlags: number
  authID: string
  status: string
  emailVerified: boolean
  firstName: string
  lastName: string
  userName: string
  externalID: string
  companyName: string
}

export interface IUserClient {
  getUsers: () => Promise<IUser[] | undefined>
  getUser: (userId: string) => Promise<IUserInsurer | undefined>
  getUsersGroups: () => Promise<UserGroup[] | undefined>
  setUserInvite: (data: unknown) => Promise<AxiosResponse>
  addUser: (data: unknown) => Promise<AxiosResponse>
  editUser: (
    userId: string,
    data: IUserUpdateRequest
  ) => Promise<boolean | undefined> // We will always expect a bool, this was put in due to type concerns
  setUserDisable: (userID: string) => Promise<IUser | undefined>
  setUserResend: (userID: string) => Promise<IUser | undefined>
  setUserGroup: (data: { name: string }) => Promise<UserGroup | undefined>
  createUser: (userEmail: string) => Promise<{ id: string } | undefined>
  search: (
    query: string,
    userRoles: EUserGroups[]
  ) => Promise<IUser[] | undefined>
}

export interface IQuoteBundle {
  embargoes: IQuoteEmbargo[]
  requestedQuote: IQuote
  originalPolicyMeta: IPolicyMeta
  policyMeta: IPolicyMeta
  requestedQuoteScheme: IScheme
  requestedQuotePricing: IRequestedQuotePricing
  risk: ISpecifiedRisk
  changes?: IQuoteChange[]
  maxQuoteExpiryDate?: string
}

export interface IRequestedQuotePricing {
  discountState: Nullable<string>
  plans: IQuotePremiumPlan[]
}

export interface IPolicyMeta {
  policyHistoryID: string
  policyID: string
  policyReferenceID: string
}

export interface IQuoteEmbargo {
  id: string
  dateCreated: string
  enabled: boolean
  startTime: string
  endTime: string
  ErrorMessage: string
}
export type DeclinedStatus = "Recoverable" | "Declined"
export type QuoteStatus =
  | "Draft"
  | "Confirmed"
  | "Withdrawn"
  | "Invalidated"
  | "PendingReferral"
export type QuoteType = "NewBusiness" | "MTA" | "Vri" | "Renewal"
export interface IQuoteDeclineReason {
  coverageID: Nullable<string>
  id: string
  referenceID: string
  sectionID: Nullable<string>
  subSectionID: Nullable<string>
  text: string
  title: Nullable<string>
}
export interface IQuoteReferralReason {
  coverageID: Nullable<string>
  referenceID: string
  sectionID: Nullable<string>
  subSectionID: Nullable<string>
  text: string
}
export interface IQuote {
  expiryDate: string
  expired: boolean
  declined: boolean
  declinedStatus: DeclinedStatus
  declineReasons: IQuoteDeclineReason[]
  converted: boolean
  id: string
  coverType: string
  dateCreated: string
  documents: IDocument[]
  quoteReferenceID: IQuoteState
  policyReferenceID: string
  lineOfBusiness: string
  quoteType: QuoteType
  quoteStatus: QuoteStatus
  quoteState: IQuoteState
  estimateGross: { value: number; currencyCode: string }
  payment: boolean
  ownerID: string
  policyID: string
  product: {
    name: string
    version: number
  }
  policyMeta: IPolicyMeta
  schemeID: string
  partnerID: string
  partnerReference: string
  productID: number
  referred: boolean
  referralReasons: IQuoteReferralReason[]
  sourceQuote: string
  productReferenceID?: string
  productCoverReference?: string
  endorsements: IEndorsementV6[]
  isExternal: boolean
}

export type ISpecifiedRisk = {
  coverEndDate: string
  coverStartDate: string
  coverType: string
  productReferenceID: string
  property: {
    address: {
      country: { referenceID: string; text: string }
      referenceID: string
      text: string
      line1: string
      line2: string
      line3: string
      locality: string
      postalCode: string
      province: string
      provinceReferenceID: string
      uniqueReference: null
    }
  }
  proposer: {
    emailAddress: string
    personName: {
      firstName: string
      lastName: string
    }
  }
  limitOfLiability?: IMoney
}

export interface IQuotePremiumPlan {
  addOnFinalPrices: IPricingComponentPrice[]
  aprRate: number
  basePrice: IPricingPrice
  encryptedState: string
  facilityFee: IMoney
  fees: IPricingServiceFee[]
  feesTotal: IMoney
  finalPrice: IPricingPrice
  firstInstalmentAmount: IMoney
  firstRecurringPaymentDate: string
  interestRate: number
  monthlyPrice: IMoney
  numberOfInstallments: number
  paymentPlan: IPaymentPlan
  primaryRateableComponentFinalPrices: IPricingRateableComponentPrice[]
  totalPayable: IPricingPrice
  upfrontPrice: IMoney
}

export interface IPaymentPlan {
  depositPaymentProvider: PaymentProvider
  paymentPlanReferenceID: string
  paymentProvider: PaymentProvider
  type: PaymentPlanType
}

export type PaymentPlanType =
  | "NotSet"
  | "FixedTermSinglePayment"
  | "AnnualMonthlyCredit"
  | "AnnualMonthlySubscription"
  | "MonthlySubscription"
  | "ExternalCollection"

export type PaymentProvider =
  | "None"
  | "Realex"
  | "Stripe"
  | "PremiumCredit"
  | "ExternalCollection"
  | "AttvestCredit"
  | "AttvestSingle"

export interface IPricingServiceFee {
  amount: IMoney
  name: string
  referenceID: string
}

export interface IPricingComponentPrice {
  price: IPricingPrice
  schemeID: number
}

export interface IPricingRateableComponentPrice {
  isStandaloneProduct: boolean
  price: IPricingPrice
  referenceID: string
}

export interface IPricingPrice {
  gross: IMoney
  net: IMoney
  tax: IFinanceTaxSet
  totalInterest: IMoney
  iptTotal: IMoney
  iptPercentage: number
}

export interface IFinanceTaxSet {
  taxes: IFinanceTax[]
  taxRuleID: string
  taxPlanReferenceID: string
  total: IMoney
}

export interface IFinanceTax {
  amount: IMoney
  name: string
  order: number
  percentage: number
  taxReferenceID: string
}

export interface IQuoteNote {
  content: string
  createdByName: string
  createdByUserID: string
  dateCreated: string
  entityID: string
  entityType: NoteEntityType
  entityReferenceID: string
  id: string
  isInternalOnly: boolean
}

export type NoteEntityType =
  | "Unknown"
  | "Document"
  | "Bill"
  | "Policy"
  | "PolicyDocument"
  | "PersonProfile"
  | "PropertyProfile"
  | "CarProfile"
  | "HomeBuyersCover"
  | "QuotePack"
  | "Quote"
  | "QuoteBundle"
  | "HomeBuyersRisk"
  | "HomeBuyersPolicyDetails"
  | "PolicyBundle"
  | "Scheme"
  | "CoverItem"
  | "Payment"
  | "HomeBuyersScheme"
  | "Conviction"
  | "HomeCover"
  | "HomeContentsCover"
  | "HomeBuildingsCover"
  | "PedalCycle"
  | "AdditionalItem"
  | "PreviousClaims"
  | "HomeQuoteDetails"
  | "QuoteRisk"
  | "Business"
  | "PolicyRefund"
  | "PolicyClaim"
  | "Compliance"
  | "HomeRisk"
  | "Excesses"
  | "AddOnSelection"
  | "PolicyHistory"
  | "PolicyHistoryAddOn"
  | "QuoteAddOn"
  | "CreditAgreement"
  | "CreditAgreementHistory"
  | "QuickQuote"
  | "PolicyAdditionalProduct"
  | "FakePersonProfile"
  | "FakeExtraInfo"
  | "FakeCover"
  | "BasicPolicyBundle"
  | "Endorsement"
  | "ProductItem"
  | "Note"

export interface IQuoteSearchResponse {
  results: IPortalQuoteTable[]
}

export interface IQuoteWithdrawReason {
  referenceID: string
  title: string
  text: string
}

export interface ISetQuoteWithdrawReason {
  comment?: string
  withdrawalReasonReferenceID?: string
}

export interface IQuotePaymentLink {
  paymentURL: string
  expired: boolean
  expiryDateTime: string
}

export interface IEndorsement extends IEndorsementOpus {
  checked: boolean
}

export interface ICreatePaymentLinkResponse {
  paymentURL: string
  expiryDateTime: string
  expired: boolean
}

export interface IQuoteClient {
  getQuoteBundle: (quoteID: string) => Promise<IQuoteBundle | undefined>
  getQuotes: (
    quoteTypes: string[],
    status?: string
  ) => Promise<IPortalQuoteTable[] | undefined>
  getQuote: (quoteID: string) => Promise<IQuote | undefined>
  getQuotePaymentPlans: (
    quoteID: string
  ) => Promise<IQuotePremiumPlan[] | undefined>
  getQuoteProduct: (quoteID: string) => Promise<IProductOpus | undefined>
  getQuoteNotes: (quoteID: string) => Promise<IQuoteNote[] | undefined>
  getQuoteEvents: (quoteID: string) => Promise<IQuoteEvent[] | undefined>
  getQuoteSearch: (query: string) => Promise<IQuoteSearchResponse | undefined>
  getQuoteDocuments: (quoteID: string) => Promise<IDocument[] | undefined>
  getQuotesWithdrawReasons: (
    quoteID: string
  ) => Promise<IQuoteWithdrawReason[] | undefined>
  getQuoteReview: (quoteID: string) => Promise<IQuoteReviewResponse | undefined>
  getQuotePaymentLink: (
    quoteID: string
  ) => Promise<IQuotePaymentLink | undefined>
  addRemoveEndorsements: (
    quoteID: string,
    addedEndorsements: Nullable<IEndorsementOpus[]>,
    comment: Nullable<string>,
    deletedEndorsements: Nullable<IEndorsementOpus[]>
  ) => Promise<IQuote | undefined>
  editEndorsement: (
    quoteID: string,
    referenceID: string,
    title: string,
    text: string
  ) => Promise<IQuote | undefined>
  invalidateQuotePaymentLink: (quoteID: string) => Promise<void>
  assignQuoteToUser: (
    quoteID: string,
    userID: string
  ) => Promise<boolean | undefined>
  setQuoteWithdraw: (
    quoteID: string,
    data: ISetQuoteWithdrawReason
  ) => Promise<IQuote | undefined>
  setQuoteReview: (
    quoteID: string,
    reviewValue: boolean
  ) => Promise<IQuoteReviewResult | undefined>
  getQuoteRenewalReview: (
    quoteID: string
  ) => Promise<IQuoteReviewResult | undefined>
  setQuoteNote: (
    quoteID: string,
    data: { content: string }
  ) => Promise<IQuoteNote | undefined>
  setQuoteRefer: (quoteID: string) => Promise<IQuote | undefined>
  setQuoteConfirm: (
    quoteID: string,
    comment?: string
  ) => Promise<IQuote | undefined>
  setQuotePaymentLink: (
    quoteID: string
  ) => Promise<ICreatePaymentLinkResponse | undefined>
  referQuote: (quoteID: string, comment?: string) => Promise<IQuote | undefined>
  anonymiseQuote: (quoteID: string) => Promise<IQuoteBundle | undefined>
}

export interface IOpusPayOnAccount {
  bindQuote: (
    quoteID: string,
    pricingPlanReferenceID: string,
    encryptedDiscount?: string | null,
    paymentReference?: string | null
  ) => Promise<IPolicyBundle>
}

export interface IBlacklistClient {
  getBlacklist: () => Promise<IBlacklistedPhoneNumber[] | undefined>
  blacklistPhoneNumber: (phoneNumber: string) => Promise<boolean | undefined>
  removePhoneNumberFromBlacklist: (
    phoneNumber: string
  ) => Promise<boolean | undefined>
  phoneNumberIsBlacklisted: (
    phoneNumber: string
  ) => Promise<boolean | undefined>
}

export interface IRatingsClient {
  list: () => Promise<IRateFile[] | undefined>
  productRateVersions: () => Promise<IProductRateVersion[] | undefined>
  uploadRatings: (formData: FormData) => Promise<boolean>
  updateGoLiveDate: (
    rateFileID: string,
    newGoLiveDate: string
  ) => Promise<boolean>
}

export interface IOpusClient {
  token?: string
  getToken: () => Promise<string>
  userClient: IUserClient
  quoteClient: IQuoteClient
  policyClient: IPolicyClient
  renewalClient: IRenewalClient
  payOnAccount: IOpusPayOnAccount
  embargoesClient: IEmbargoesClient
  blacklistClient: IBlacklistClient
  ratingsClient: IRatingsClient
}

export interface IOpusContext {
  opusToken?: string
  getToken: () => Promise<string>
  userClient: IUserClient
  quoteClient: IQuoteClient
  policyClient: IPolicyClient
  renewalClient: IRenewalClient
  payOnAccount: IOpusPayOnAccount
  embargoesClient: IEmbargoesClient
  commandClient: ICommandClient
  blacklistClient: IBlacklistClient
  ratingsClient: IRatingsClient
}

export const initialOpusState: IOpusContext = {
  opusToken: undefined,
  getToken: stub,
  userClient: {
    getUsers: stub,
    getUsersGroups: stub,
    getUser: stub,
    editUser: stub,
    setUserInvite: stub,
    addUser: stub,
    setUserDisable: stub,
    setUserResend: stub,
    setUserGroup: stub,
    createUser: stub,
    search: stub
  },
  quoteClient: {
    getQuoteBundle: stub,
    getQuotes: stub,
    getQuote: stub,
    getQuotePaymentPlans: stub,
    getQuoteProduct: stub,
    getQuoteNotes: stub,
    getQuoteEvents: stub,
    getQuoteSearch: stub,
    getQuoteDocuments: stub,
    getQuotesWithdrawReasons: stub,
    getQuoteReview: stub,
    setQuoteWithdraw: stub,
    setQuoteNote: stub,
    setQuoteRefer: stub,
    addRemoveEndorsements: stub,
    editEndorsement: stub,
    setQuoteConfirm: stub,
    setQuotePaymentLink: stub,
    getQuotePaymentLink: stub,
    setQuoteReview: stub,
    getQuoteRenewalReview: stub,
    invalidateQuotePaymentLink: stub,
    assignQuoteToUser: stub,
    referQuote: stub,
    anonymiseQuote: stub
  },
  policyClient: {
    getPolicyHistory: stub,
    getPolicies: stub,
    getPolicyEvents: stub,
    getPolicyBundle: stub,
    getPolicyActions: stub,
    getPolicySearch: stub,
    getPolicyRenewals: stub,
    getPolicyRenewalsSettings: stub,
    getPolicyRenewalQuotes: stub,
    setPolicyRenewals: stub,
    setPolicyRenewalWithdraw: stub,
    setPolicyRenewalQuote: stub,
    getPolicyCancelInfo: stub,
    getPolicyCancellationInformation: stub,
    setPolicyCancellation: stub,
    reinstatePolicy: stub,
    updateCoverStartDate: stub,
    getPolicyPaymentPlan: stub,
    manualRefund: stub,
    manualPayment: stub,
    getPolicyPaymentMethod: stub,
    payScheduledPayment: stub,
    getPolicyPayments: stub,
    downloadTransactionReceipt: stub,
    emailTransactionReceipt: stub,
    getTransactionalMessages: stub,
    resendTransactionalEmail: stub,
    resendDocument: stub,
    shiftCancellation: stub
  },
  renewalClient: {
    setActiveRenewalQuote: stub,
    duplicateRenewalQuote: stub
  },
  payOnAccount: {
    bindQuote: stub
  },
  embargoesClient: {
    getEmbargoes: stub,
    addEmbargo: stub,
    getEmbargo: stub,
    setEmbargoCancel: stub
  },
  commandClient: {
    regenerateDocument: stub
  },
  blacklistClient: {
    getBlacklist: stub,
    blacklistPhoneNumber: stub,
    removePhoneNumberFromBlacklist: stub,
    phoneNumberIsBlacklisted: stub
  },
  ratingsClient: {
    list: stub,
    productRateVersions: stub,
    uploadRatings: stub,
    updateGoLiveDate: stub
  }
}

const OpusContext = createContext<IOpusContext>(initialOpusState)

export default OpusContext
