import React, { useState, useEffect } from "react"
import { LayoutPageContent } from "components"
import { Layout, Row, Col, Statistic, Tabs, Space, Badge } from "antd"
import { useConfigurationContext } from "contexts/configuration/context"
import { IPolicyEvent } from "models/events/IEvent"
import {
  useGetPolicyEvents,
  useGetPolicyActions,
  useGetPolicyRenewalsSettings
} from "containers/policies/services/hooks/"
import { PolicyTabs, PolicyHeader } from "./v2_components"
import {
  PolicySummarySubView,
  PolicyHistorySubView,
  PolicyRenewalsSubView
} from "./v2_sub-views"
import { useDefaultPage } from "utils/hooks/use-default-page"
import {
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
  useHistory,
  useRouteMatch
} from "react-router-dom"
import { StaticContext } from "react-router"
import {
  EOpusPermission,
  useOpusPermissions
} from "contexts/authorization/hooks/use-opus-permissions"
import { IPolicyAction } from "models/policies/policyAction"
import formatDate from "dateformat"
import { PolicyDocumentsView } from "./v2_sub-views/documents"
import { PolicyAnswers } from "./v2_sub-views/policyAnswers"
import { MoneyStatistic } from "components/display/MoneyStatistic"
import useOpus from "contexts/opus/hooks/use-opus"
import { useQuery } from "react-query"
import { PolicyOracleResults } from "./sub-views/oracleResults"
import EndorsementsTable from "components/Endorsements"
import { useGetAppConfig } from "contexts/configuration/hooks/use-get-app-config"
import { PolicyPaymentsSubView } from "./v2_sub-views/Payments"
import { TransactionalMessagesSubView } from "components/Messages"

const PolicyDetailsComponent: React.FunctionComponent<PolicyDetailsProps> = (
  props
) => {
  const history = useHistory()

  const { path } = useRouteMatch()

  const { policyClient } = useOpus()

  const policyID = props.match.params.id

  // Policy

  const {
    data: policyBundle,
    isLoading: isPolicyLoading,
    isError,
    refetch: refetchPolicyBundle
  } = useQuery(["PolicyBundle", policyID], () =>
    policyClient.getPolicyBundle(policyID)
  )

  useDefaultPage(isError ? 400 : 200, ["404", "400"], {
    status: "404",
    title: `Sorry, we couldn't find a policy that matched those details.`,
    buttonText: "Back to Policies",
    redirectTo: "/policies"
  })

  // Spoke to Hoskies around this, he suggested that this permission be used until we need to differtiate these
  const { havePermission: canUseNewQuote } = useOpusPermissions(
    EOpusPermission.Quote,
    "create"
  )

  const { havePermission: canCancelPolicy } = useOpusPermissions(
    EOpusPermission.Policy,
    "cancel"
  )

  const { havePermission: canEditCardDetails } = useOpusPermissions(
    EOpusPermission.EditCardDetails,
    "true"
  )

  const { havePermission: canActionManualPayments } = useOpusPermissions(
    EOpusPermission.ManualPayments,
    "true"
  )

  const [policyEvents, setPolicyEvents] = useState<IPolicyEvent[] | undefined>()

  const {
    events,
    isEventsLoading,
    isError: isPolicyEventsError,
    reloadEvents: refetchEvents
  } = useGetPolicyEvents(props.match.params.id)

  useEffect(() => {
    setPolicyEvents(events)
  }, [events])

  // Policy Actions

  const [policyActions, setPolicyActions] = useState<
    IPolicyAction[] | undefined
  >()

  const { actions, areActionsLoading } = useGetPolicyActions(
    props.match.params.id
  )

  useEffect(() => {
    setPolicyActions(actions)
  }, [actions])

  const { policyRenewalsSettings } = useGetPolicyRenewalsSettings(
    policyBundle?.policy.productID?.toString()
  )

  const configuration = useConfigurationContext()

  const policyHistory = policyBundle?.policy.policyHistory

  const isPolicyCancelled = policyBundle?.policy.policyStatus === "Cancelled"

  const { applicationConfig } = useGetAppConfig()

  const activeLines = applicationConfig?.activeLinesOfBusiness
    .map((activeLine) => {
      if (activeLine.isActive) {
        return activeLine.coverTypes.map((coverType) => ({
          ...coverType,
          ...{ id: activeLine.id, productType: activeLine.productType }
        }))
      }
      return null
    })
    .flat()
    .filter((item) => item)

  const isHybrid =
    activeLines?.find(
      (qct) =>
        qct?.productReferenceId === policyBundle?.policy.productReferenceID
    )?.productType === "Hybrid"

  return (
    <>
      <Layout>
        <PolicyHeader
          documents={policyBundle?.policy?.documents}
          title={policyBundle?.policy?.policyReferenceID}
          type={policyBundle?.policy?.policyStatus}
          policyTypes={configuration?.policy?.state}
          productRef={policyBundle?.product.referenceID}
          productId={policyBundle?.product.id}
          coverType={policyBundle?.risk.coverType}
          policyId={policyBundle?.policy.id}
          userId={policyBundle?.policy.ownerID}
          ownerId={policyBundle?.policy.ownerID}
          policyActions={policyActions}
          mtaPermission={canUseNewQuote}
          cancelPolicyPermission={canCancelPolicy}
          policyCoverRange={
            [
              policyBundle?.policy?.coverStartDate,
              policyBundle?.policyDetails?.coverEndDate
            ] as string[]
          }
          policyCoverEndDate={policyBundle?.policy.coverEndDate}
          policyLineOfBusiness={policyBundle?.policy.lineOfBusiness}
          cancellationReason={policyBundle?.policy?.cancellationReason}
          policyPurchaseDate={policyBundle?.policy?.localDateCreated}
          paymentPlanType={policyBundle?.creditAgreement.paymentPlanType}
          refetchPolicyBundle={() => {
            refetchPolicyBundle()
          }}
          refetchTimelineEvents={refetchEvents}
          policyReferenceID={policyBundle?.policy.policyReferenceID}
          hasReinstatementEmail={
            policyBundle?.product.hasReinstatementEmail ?? undefined
          }
          recurringPaymentDates={
            policyBundle?.creditAgreement.recurringPaymentDates
          }
          isExternal={policyBundle?.policy.isExternal}
          additionalProducts={policyBundle?.additionalProducts ?? []}
          footer={
            <Tabs
              defaultActiveKey={props.match.params.subpage}
              activeKey={props.match.params.subpage}
              onTabClick={(activeKey) => {
                switch (activeKey) {
                  case "summary":
                    history.push(`/policy/${props.match.params.id}/summary`)
                    break
                  case "endorsements":
                    history.push(
                      `/policy/${props.match.params.id}/endorsements`
                    )
                    break
                  case "documents":
                    history.push(`/policy/${props.match.params.id}/documents`)
                    break
                  case "history":
                    history.push(`/policy/${props.match.params.id}/history`)
                    break
                  case "underwriting":
                    history.push(
                      `/policy/${props.match.params.id}/underwriting`
                    )
                    break
                  case "answers":
                    history.push(`/policy/${props.match.params.id}/answers`)
                    break
                  case "payments":
                    history.push(`/policy/${props.match.params.id}/payments`)
                    break
                  case "messages":
                    history.push(`/policy/${props.match.params.id}/messages`)
                    break
                  case "renewals":
                    history.push(`/policy/${props.match.params.id}/renewals`)
                    break
                  case "oracleresults":
                    history.push(
                      `/policy/${props.match.params.id}/oracleresults`
                    )
                    break
                }
              }}
            >
              <Tabs.TabPane tab="Summary" key="summary" />
              <Tabs.TabPane
                tab={
                  <Space align="center">
                    Endorsements
                    <Badge
                      count={
                        (policyHistory &&
                          policyHistory.endorsements &&
                          policyHistory.endorsements.length) ??
                        0
                      }
                      showZero
                      style={{
                        marginTop: "-5px",
                        backgroundColor: "var(--ant-primary-color)"
                      }}
                      dot={false}
                    />
                  </Space>
                }
                key="endorsements"
              />
              <Tabs.TabPane tab="Documents" key="documents" />
              <Tabs.TabPane tab="Messages" key="messages" />
              <Tabs.TabPane tab="History" key="history" />
              <Tabs.TabPane tab="Payments" key="payments" />
              {configuration.flags.quoteVersion === "v7" && (
                <Tabs.TabPane tab="Underwriting" key="underwriting" />
              )}

              {(isHybrid || configuration.flags.quoteVersion === "v7") && (
                <>
                  <Tabs.TabPane tab="Answers" key="answers" />
                  <Tabs.TabPane tab="Data Oracle Results" key="oracleresults" />
                </>
              )}

              {configuration.flags.showRenewalsTab &&
                policyRenewalsSettings?.supportsRenewals && (
                  <Tabs.TabPane tab="Renewals" key="renewals" />
                )}
            </Tabs>
          }
        >
          <Row gutter={24}>
            <Col>
              <MoneyStatistic
                title={"Premium Paid"}
                value={policyBundle?.policy?.grossPremium}
              />
            </Col>
            <Col>
              <Statistic
                title={"Expiry date"}
                value={
                  policyBundle?.policy?.coverEndDate
                    ? formatDate(
                        new Date(policyBundle?.policy?.coverEndDate),
                        "dd/mm/yyyy"
                      )
                    : "-"
                }
              />
            </Col>
            <Col>
              <Statistic
                title={"Last updated"}
                value={
                  policyBundle?.policy?.policyHistory?.dateCreated
                    ? formatDate(
                        new Date(
                          policyBundle?.policy?.policyHistory?.dateCreated
                        ),
                        "dd/mm/yyyy"
                      )
                    : "-"
                }
              />
            </Col>
          </Row>
        </PolicyHeader>

        <LayoutPageContent
          error={(isError && { errorNumber: 400 }) || isPolicyEventsError}
          transparent
          siderContent={
            <PolicyTabs
              events={policyEvents}
              eventsActions={configuration?.policy?.action || {}}
              isEventsLoading={isEventsLoading}
            />
          }
        >
          <Switch>
            <Route
              path={`${path}/summary`}
              render={() => (
                <PolicySummarySubView
                  isPolicyLoading={isPolicyLoading}
                  areActionsLoading={areActionsLoading}
                  policy={policyBundle}
                  policyPlanReferenceID={
                    policyBundle?.policy.paymentPlanReferenceID
                  }
                  refetchPolicyBundle={() => {
                    refetchPolicyBundle()
                    refetchEvents()
                  }}
                  refetchTimelineEvents={refetchEvents}
                />
              )}
            />
            <Route
              path={`${path}/endorsements`}
              render={() => (
                <EndorsementsTable
                  endorsements={
                    (policyHistory && policyHistory.endorsements) || []
                  }
                />
              )}
            />
            <Route
              path={`${path}/answers`}
              render={() => (
                <LayoutPageContent>
                  <PolicyAnswers policyId={props.match.params.id} />
                </LayoutPageContent>
              )}
            />
            <Route
              path={`${path}/oracleresults`}
              render={() => (
                <LayoutPageContent>
                  <PolicyOracleResults policyId={props.match.params.id} />
                </LayoutPageContent>
              )}
            />
            <Route
              path={`${path}/documents`}
              render={() => (
                <PolicyDocumentsView
                  policyId={policyBundle?.policy.id}
                  documents={policyBundle?.policy.documents.map((document) => ({
                    documentUrl: document.documentUrl,
                    fileExtension: null,
                    fileName: document.filename,
                    fileType: document.type,
                    policyDocumentID: document.id,
                    directDocumentID: document.directDocumentID,
                    policyDocumentStatus: document.policyDocumentStatus,
                    version: document.version,
                    localDateCreated: document.dateCreated,
                    referenceID: document.referenceID
                  }))}
                  emailAddress={policyBundle?.risk.proposer.emailAddress}
                />
              )}
            />

            <Route
              path={`${path}/history`}
              render={() => (
                <PolicyHistorySubView policyID={props.match.params.id} />
              )}
            />

            <Route
              path={`${path}/payments`}
              render={() => (
                <PolicyPaymentsSubView
                  policyID={props.match.params.id}
                  policyReferenceId={
                    policyBundle?.policy.policyReferenceID ?? ""
                  }
                  ownerId={policyBundle?.policy.ownerID ?? ""}
                  refetchTimelineEvents={refetchEvents}
                  paymentPlanType={
                    policyBundle?.creditAgreement.paymentPlanType
                  }
                  canEditCardDetails={canEditCardDetails}
                  canActionManualPayments={canActionManualPayments}
                  emailAddress={policyBundle?.risk.proposer.emailAddress ?? ""}
                />
              )}
            />

            <Route
              path={`${path}/messages`}
              render={() => (
                <TransactionalMessagesSubView
                  entityID={props.match.params.id}
                />
              )}
            />

            {configuration.flags.showRenewalsTab &&
              policyRenewalsSettings?.supportsRenewals && (
                <Route
                  path={`${path}/renewals`}
                  render={() => (
                    <PolicyRenewalsSubView
                      isPolicyCancelled={isPolicyCancelled}
                      policyProductID={policyBundle?.policy.productID}
                      policyID={props.match.params.id}
                      policyRenewalsSettings={policyRenewalsSettings}
                      policyPlanReferenceID={
                        policyBundle?.policy.paymentPlanReferenceID
                      }
                    />
                  )}
                />
              )}

            {!props.match.params.subpage && <Redirect to={`${path}/summary`} />}
          </Switch>
        </LayoutPageContent>
      </Layout>
    </>
  )
}

export type PolicyDetailsProps = RouteComponentProps<
  {
    id: string
    subpage: string
  },
  StaticContext,
  {
    data?: string
  }
>

export default PolicyDetailsComponent
