import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Modal,
  Row,
  Select,
  Space,
  Spin
} from "antd"
import { IPolicyCancelInfoRequestBody } from "contexts/opus/context"
import useOpus from "contexts/opus/hooks/use-opus"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import styled from "styled-components"
import { notification } from "antd"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { PaymentPlanType } from "platform-client/types"
import { DollarOutlined, ReloadOutlined, SendOutlined } from "@ant-design/icons"
import { IAdditionalProduct } from "models/policies/additionalProduct"

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledCol = styled(Col)`
  & > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
`

const StyledDiv = styled("div")`
  margin-bottom: 20px;
`

const StyledSelect = styled(Select)`
  width: 100%;
`

const StyledDatepicker = styled(DatePicker)`
  width: 100%;
`

const PolicyCancellationAmendment: React.FC<IPolicyCancellationAmendmentProps> =
  ({
    policyID,
    policyCoverRange,
    policyCoverEndDate,
    policyPurchaseDate,
    policyLineOfBusiness,
    refetchPolicyBundle,
    currentCancellationReasonId,
    refetchTimelineEvents,
    paymentPlanType,
    additionalProducts
  }) => {
    const [modalVisible, setModalVisible] = useState(false)

    const minCancellationDate = useMemo(() => {
      if (policyPurchaseDate && policyCoverRange && policyCoverRange.length) {
        const purchaseDate = moment.utc(policyPurchaseDate).startOf("day")
        const coverStart = moment.utc(policyCoverRange[0]).startOf("day")

        if (policyLineOfBusiness === "motor") {
          return coverStart
        } else if (policyLineOfBusiness === "travel") {
          return purchaseDate
        }

        return moment.min([purchaseDate, coverStart])
      }
    }, [policyLineOfBusiness, policyPurchaseDate, policyCoverRange])

    const coverRange = useMemo(() => {
      if (policyCoverRange && policyCoverRange.length) {
        return [
          moment.utc(policyCoverRange[0]),
          moment.utc(policyCoverRange[1]).endOf("day")
        ]
      }
    }, [policyCoverRange])

    const defaultDate = useMemo(() => {
      const today = moment.utc().startOf("day")

      if (policyCoverEndDate)
        return moment.utc(policyCoverEndDate).startOf("day")

      if (!coverRange || (coverRange && coverRange[1].isAfter(today))) {
        return today
      }

      return coverRange[1]
    }, [coverRange, policyCoverEndDate])

    const additionalProductOptions =
      additionalProducts
        ?.filter((ap) => ap.cancelledOnLocal === null)
        .map((ap) => ({ label: ap.name, value: ap.referenceID })) ?? []

    const [cancellationRequest, setCancellationRequest] =
      useState<IPolicyCancelInfoRequestBody>({
        policyID,
        selectedCancellationReasonID: currentCancellationReasonId,
        allowRefund: false,
        effectiveCancellationDate: defaultDate.format(),
        suppressCancellationEmail: true,
        regenerateDocuments: true
      })

    const cancellationInfoMutation = useMutation(
      (request: IPolicyCancelInfoRequestBody) =>
        opusClient.policyClient.getPolicyCancellationInformation(
          request.policyID,
          request
        )
    )

    const opusClient = useOpus()

    const cancellationInfoAmendmentMutation = useMutation(
      (request: IPolicyCancelInfoRequestBody) =>
        opusClient.policyClient.shiftCancellation({
          policyID,
          cancellationReasonReferenceID:
            request.selectedCancellationReason?.referenceID,
          effectiveCancellationDate: request.effectiveCancellationDate,
          emailDocuments: !request.suppressCancellationEmail,
          regenerateDocuments: request.regenerateDocuments,
          issueRefund: request.allowRefund,
          refundMethodOverride: request.refundMethodOverride,
          additionalProductsToCancel: request.additionalProductsToCancel
        })
    )

    const onCloseModal = () => {
      setModalVisible(false)
    }

    const handleAmendment = async () => {
      if (policyID) {
        await cancellationInfoAmendmentMutation
          .mutateAsync(cancellationRequest)
          .then(() => {
            setModalVisible(false)
            notification.success({
              message: "Cancellation info updated successfully."
            })
            refetchPolicyBundle && refetchPolicyBundle()
            refetchTimelineEvents && refetchTimelineEvents()
          })
          .catch(() => {
            notification.error({
              message:
                "An error occurred when attempting to update the cancellation info. Please try again, or contact support."
            })
          })
      }
    }

    const fetchCancellationInfo = (request: IPolicyCancelInfoRequestBody) => {
      cancellationInfoMutation.mutateAsync(request)
    }

    const onAmendClick = () => {
      setModalVisible(true)
      fetchCancellationInfo(cancellationRequest)
    }

    useEffect(() => {
      if (
        cancellationInfoMutation?.data?.cancellationReasons?.length &&
        currentCancellationReasonId &&
        !cancellationRequest.selectedCancellationReason
      ) {
        const reason = cancellationInfoMutation?.data?.cancellationReasons.find(
          (x) => x.id === currentCancellationReasonId
        )
        const newRequest = {
          ...cancellationRequest,
          selectedCancellationReason: reason
        }
        setCancellationRequest(newRequest)
      }
    }, [
      cancellationInfoMutation?.data?.cancellationReasons,
      cancellationRequest,
      currentCancellationReasonId
    ])

    return (
      <>
        <Button loading={false} onClick={onAmendClick}>
          Amend Cancellation Info
        </Button>
        <Modal
          title="Amend Cancellation Info"
          visible={modalVisible}
          onCancel={onCloseModal}
          footer={
            <Space direction="horizontal">
              <Button onClick={onCloseModal}>Close</Button>
              <Button
                type="primary"
                onClick={handleAmendment}
                loading={cancellationInfoAmendmentMutation.isLoading}
              >
                Update
              </Button>
            </Space>
          }
        >
          <Spin spinning={cancellationInfoMutation.isLoading}>
            <h2>Cancellation Details</h2>
            <StyledDiv>
              <h4>Reason</h4>
              <StyledSelect
                placeholder="Select cancellation reason"
                value={
                  cancellationRequest.selectedCancellationReason?.referenceID
                }
                onChange={(referenceID) => {
                  const newReason =
                    cancellationInfoMutation?.data?.cancellationReasons?.find(
                      (reason) => reason.referenceID === referenceID
                    )

                  const request = {
                    ...cancellationRequest,
                    selectedCancellationReason: newReason
                  }
                  setCancellationRequest(request)
                  fetchCancellationInfo(request)
                }}
                options={cancellationInfoMutation?.data?.cancellationReasons?.map(
                  (reason) => ({
                    label: reason.text,
                    value: reason.referenceID
                  })
                )}
              />
            </StyledDiv>
            <StyledDiv>
              <h4>Effective Date</h4>
              <StyledDatepicker
                disabledDate={(current) => {
                  if (minCancellationDate && coverRange) {
                    return !(
                      minCancellationDate.isSameOrBefore(current) &&
                      coverRange[1].isSameOrAfter(current)
                    )
                  }

                  return true
                }}
                id="effectiveCancellationDate"
                format="DD/MM/YYYY"
                allowClear={false}
                value={moment(cancellationRequest.effectiveCancellationDate)}
                onChange={(date) => {
                  const request = {
                    ...cancellationRequest,
                    effectiveCancellationDate:
                      date?.startOf("day")?.format("YYYY-MM-DDTHH:mm:ss") + "Z"
                  }
                  setCancellationRequest(request)
                  fetchCancellationInfo(request)
                }}
              />
            </StyledDiv>
            {additionalProductOptions.length > 0 && (
              <StyledDiv>
                <Checkbox.Group
                  onChange={(event) => {
                    const request = {
                      ...cancellationRequest,
                      additionalProductsToCancel: event as string[]
                    }
                    setCancellationRequest(request)
                    fetchCancellationInfo(request)
                  }}
                  value={cancellationRequest.additionalProductsToCancel}
                  style={{ width: "100%" }}
                >
                  <StyledRow>
                    {additionalProductOptions.map((ap) => (
                      <>
                        <h4>{ap.label}</h4>
                        <Checkbox value={ap.value} />
                      </>
                    ))}
                  </StyledRow>
                </Checkbox.Group>
              </StyledDiv>
            )}
            <StyledDiv>
              <h2>Refund Details</h2>
              <Descriptions
                size="small"
                column={1}
                style={{ margin: "20px 0 0" }}
              >
                <Descriptions.Item label="Cover period start">
                  {coverRange ? coverRange[0].format("DD/MM/YYYY") : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Cover period end">
                  {coverRange ? coverRange[1].format("DD/MM/YYYY") : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Previously refunded">
                  {cancellationInfoMutation?.data?.previousRefundAmount ===
                  undefined ? (
                    "$-"
                  ) : (
                    <MoneyDisplay
                      value={
                        cancellationInfoMutation?.data?.previousRefundAmount
                      }
                    />
                  )}
                </Descriptions.Item>
              </Descriptions>
            </StyledDiv>
            <h2>Actions</h2>
            <StyledCol>
              <StyledRow>
                <h4>
                  <DollarOutlined /> Issue refund (
                  {cancellationInfoMutation?.data?.refundAmount ===
                  undefined ? (
                    "$-"
                  ) : (
                    <MoneyDisplay
                      value={cancellationInfoMutation?.data?.refundAmount}
                    />
                  )}
                  )
                </h4>
                <Checkbox
                  id="issueRefund"
                  onChange={(event) => {
                    setCancellationRequest((prevRequest) => ({
                      ...prevRequest,
                      allowRefund: event.target.checked,
                      refundMethodOverride:
                        paymentPlanType === "AnnualMonthlySubscription" &&
                        event.target.checked
                          ? "ProRata"
                          : null
                    }))
                  }}
                  disabled={
                    cancellationInfoMutation?.data?.refundAmount?.value !=
                      null &&
                    cancellationInfoMutation?.data?.refundAmount?.value <= 0
                  }
                  checked={
                    cancellationInfoMutation?.data?.refundAmount?.value !=
                      null &&
                    cancellationInfoMutation?.data?.refundAmount?.value >= 0 &&
                    cancellationRequest.allowRefund
                  }
                />
              </StyledRow>
            </StyledCol>
            <StyledCol>
              <StyledRow>
                <h4>
                  <ReloadOutlined /> Regenerate cancellation documents
                </h4>
                <Checkbox
                  id="regenerateCancellationDocument"
                  onChange={(event) => {
                    setCancellationRequest((prevRequest) => ({
                      ...prevRequest,
                      regenerateDocuments: event.target.checked,
                      suppressCancellationEmail: true
                    }))
                  }}
                  checked={cancellationRequest.regenerateDocuments}
                />
              </StyledRow>
            </StyledCol>
            <StyledCol>
              <StyledRow>
                <h4>
                  <SendOutlined /> Email new document to the client
                </h4>
                <Checkbox
                  id="sendCancellationDocByEmail"
                  onChange={(event) => {
                    setCancellationRequest((prevRequest) => ({
                      ...prevRequest,
                      suppressCancellationEmail: !event.target.checked
                    }))
                  }}
                  checked={!cancellationRequest.suppressCancellationEmail}
                  disabled={!cancellationRequest.regenerateDocuments}
                />
              </StyledRow>
            </StyledCol>
          </Spin>
        </Modal>
      </>
    )
  }

interface IPolicyCancellationAmendmentProps {
  policyID: string
  policyCoverRange?: string[]
  policyCoverEndDate?: string
  policyPurchaseDate?: string
  policyLineOfBusiness?: string
  refetchPolicyBundle?: VoidFunction
  currentCancellationReasonId?: number
  refetchTimelineEvents?: VoidFunction
  paymentPlanType?: PaymentPlanType
  additionalProducts?: IAdditionalProduct[]
}

export default PolicyCancellationAmendment
